import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from 'react-date-picker';

import { Container, Button, Row, Form, Card, Col, FloatingLabel, Table, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import RTable from "./Table"
import { addInvoiceItem, changeInvoice, changeInvoiceItems, loadAllInvoice, loadInvoice, loadInvoiceService, loadPurchaseOrderQtyService, loadPurchaseOrderService, removeInvoiceItem, resetInvoice, chanageInvoiceStatusService, deleteInvoiceService, saveInvoiceService } from "../action/invoiceAction ";

import { loadPoLookup } from "../action/poAction";
import { notification, warningNotification } from "../action/notificationAction";
import { Confirm } from "./Confirm";
import { numberCheck } from "../constant/numberCheck";
import { format, subDays } from "date-fns";
import { savePendingService } from "../action/reportAction";
import {
  setEnableMobileMenu
} from '../constant/constant';
import { formatAccounting, formatCurrency } from "../constant/format";
import { filterClient, filterClientsSearch, filterProjects, filterProjectsSearch, filterVendorsSearch } from "../constant/filterTypeahead";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';


export default function Invoice({ minDate, entity, permitted, directAdd, dvendor, dclient, dproject, dvendorname, dcallback, dShowId, dShow, dback }) {

  const dispatch = useDispatch();

  const cltTypeRef = React.useRef(null);
  const proTypeRef = React.useRef(null);
  const venTypeRef = React.useRef(null);
  const poTypeRef = React.useRef(null);

  const cltEntryTypeRef = React.useRef(null);
  const proEntryTypeRef = React.useRef(null);
  const venEntryTypeRef = React.useRef(null);


  const [startDate, setStartDate] = useState(minDate ? new Date(minDate) : subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState("active");
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [errors, setErrors] = useState({});

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [execludeAuto, setExecludeAuto] = useState(true);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState("0");
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [showConfirm, setShowConfirm] = useState({ show: false, callBackData: null });

  // eslint-disable-next-line no-unused-vars
  const [createPermission, setCreatePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [updatePermission, setUpdatePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [closePermission, setClosePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [activePermission, setActivePermission] = React.useState(false);
  // eslint-disable-next-line no-unused-vars 
  const [deletePermission, setDeletePermission] = React.useState(false);


  React.useEffect(() => {

    setStatus("active");
    setShow(false);
    setShowUpload(false);
    setSelectedInvoiceId(null);
    setErrors({});

    dispatch(resetInvoice())

    setSelectedClientId(null);
    setSelectedProjectId(null);
    setSelectedVendorId(null);
    setSelectedName(null);


    setCreatePermission(permitted(`INVOICE-${entity.toUpperCase()}_CREATE`));
    setUpdatePermission(permitted(`INVOICE-${entity.toUpperCase()}_UPDATE`));
    setClosePermission(permitted(`INVOICE-${entity.toUpperCase()}_CLOSE`));
    setDeletePermission(permitted(`INVOICE-${entity.toUpperCase()}_DELETE`));
    setActivePermission(permitted(`INVOICE-${entity.toUpperCase()}_RE-ACTIVE`));


    setStartDate(minDate ? new Date(minDate) : subDays(new Date(), 7));
    setEndDate(new Date());

    if (directAdd) {
      setShow(true);

      if (dvendor || dvendor === 0) {
        setField("vendorid", dvendor);
      }
      else if (dclient) {
        setField("clientid", dclient);
      }
      setField("projectid", dproject);
      setField("openinvoice", "Y");
      setField("vendor_name", dvendorname ?? "");
      setField("type", "SINGLE");
    }
    else {
      setShow(false);
    }

    if (dShow && dShowId) {
      handleShow({ original: { id: dShowId } });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);



  const onChange = (dates) => {

    if(dates && dates.length>0){

      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
    else{
      setStartDate(null);
      setEndDate(null);
    
    }

  };


  React.useEffect(() => {
    return () => {
      dispatch(resetInvoice())
    }
  }, []);

  React.useEffect(() => {
    dispatch(loadPoLookup(entity.toUpperCase()));

  }, [dispatch, entity]);

  React.useEffect(() => {
    dispatch(setEnableMobileMenu(false));
  }, [dispatch])

  const invoice = useSelector((state) => state.invoice.invoice);

  const invoices = useSelector(
    (state) => state.invoice.invoices
  );

  const clients = useSelector(
    (state) => state.lookup.client
  );

  const projects = useSelector(
    (state) => state.lookup.project
  );

  const vendors = useSelector(
    (state) => state.lookup.vendor
  );

  const pos = useSelector(
    (state) => state.lookup.po
  );

  const costcenter = useSelector(
    (state) => state.costcenter.costcenter
  );

  const handleClose = () => {

    dispatch(resetInvoice());

    if (dback) {
      dback()
    }
    else {

      setShow(false);
      setSelectedInvoiceId(null);
      // search();
      setErrors({});
    }

  }

  const handleCloseUpload = () => {
    setShowUpload(false);

  }

  const handleShow = (selected) => {
    setShow(true);
    setErrors({});
    if (selected && selected.original.id) {

      setSelectedInvoiceId(selected.original.id);
      loadInvoiceService(selected.original.id)
        .then(res => {
          loadQuanties(res, res.poid, res.id)
        })
        .catch(err => dispatch(notification(err)));


      // resolve({ type: LOADINVOICE, invoice: (response.data) });

    }
    else {
      setSelectedInvoiceId(null);
      setField("date", format(new Date(), "yyyy-MM-dd"));
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors()
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let dataToPost = {};
      let method = "POST";

      if (!invoice.id) {
        dataToPost = {
          ...invoice,
          side: entity.toUpperCase(),
          status: 'Y',
          poid: invoice.poid && invoice.poid !== "0" ? invoice.poid : null,
          screen_name: `${entity}-Invoice`
        };
      }
      else {
        dataToPost = {
          ...invoice, poid: invoice.poid && invoice.poid !== "0" ? invoice.poid : null,
          screen_name: `${entity}-Invoice`
        };
        method = "PATCH";
      }

      saveInvoiceService(dataToPost, method).then(res => {

        dispatch(notification(res));
        handleClose();
        dispatch(resetInvoice());
        if (dcallback) {
          dcallback();
        }

        else if (!invoice.id) {
          search();
        }

      })
        .catch(err => {
          dispatch(notification(err));
        })
    }

  }

  const findFormErrors = () => {

    const { clientid, projectid, vendorid, vendor_name, type, date, number, items, poid, openinvoice, autonumber } = invoice
    const newErrors = {}


    if (entity === 'Client' && (!clientid || clientid === "0")) newErrors.mapping = 'Select a client!'
    if (entity === 'Vendor' && (vendorid === null || vendorid === undefined)) newErrors.mapping = 'Select a vendor!'
    if (entity === 'Vendor' && vendorid === 0 && (vendor_name === "" || !vendor_name)) newErrors.vendor_name = 'Vendor name cannot be blank'
    if (!projectid || projectid === "0") newErrors.project = 'Select a project!'

    if (openinvoice !== "Y") {
      if (!poid || poid === "0") newErrors.po = 'Select a purchase order!'
      if (!type || type === "0") newErrors.project = 'Select a type!'
    }


    if (!date || date === "") newErrors.date = 'Select a date!'
    if (invoice.autonumber!=='Y'&&(!number || number === "0")) newErrors.number = 'Invoice number should not be blank!'
    if (items.length === 0) newErrors.description = 'Should have at least one row of items to create invocie';

    if (items) {
      items.forEach((e) => {

        if (type === 'SINGLE') {

          if (!e.description || e.description === "") newErrors.description = 'Description cannot be empty';
          if (!e.basic || e.basic === "") newErrors.description = 'Basic cannot be empty';


        } else {

          if (!e.description || e.description === "") newErrors.description = 'Description cannot be empty';
          // if (!e.item || e.item === "") newErrors.description = 'Item cannot be empty';
          if (!e.qty || e.qty === "") newErrors.description = 'Qty cannot be empty';
          if (!e.rate || e.rate === "") newErrors.description = 'rate cannot be empty';
        }

        if (!e.amount || e.amount === "") newErrors.description = 'Amount cannot be empty';
      })
    }

    return newErrors
  }

  const closeInvoice = (id, status, selected) => {
    if (closePermission) {
      chanageInvoiceStatusService(id, status === "Y" ? "N" : "Y", `${entity}-Invoice`)
        .then(res => {
          dispatch(notification(res))
          search();
        })
        .catch(err => {
          dispatch(notification(err));
        });
    }
    else {
      savePendingService({
        table: 'Invoice',
        pk_id: selected.id,
        type: entity + ' Close Invoice',
        amount: selected.gross,
        sub: 'inv-no',
        name: `${selected.projectname ?? ""}-${selected.number}`,
        project: selected.projectid,
        is_rejectable: 'Y',
        is_approval: 'Y',
        status: 'P',
        screen_name: `${entity}-Invoice`
      }).then(res => {
        dispatch(notification(res));
      }).catch(err => {
        dispatch(notification(err));
      })
    }
  }

  const activateInvoice = (id, status, selected) => {
    if (activePermission) {
      chanageInvoiceStatusService(id, status === "Y" ? "N" : "Y", `${entity}-Invoice`)
        .then(res => {
          dispatch(notification(res))
          search();
        })
        .catch(err => {
          dispatch(notification(err));
        });
    }
    else {
      savePendingService({
        table: 'Invoice',
        pk_id: selected.id,
        type: entity + ' Reactivate Invoice',
        amount: selected.gross,
        sub: 'inv-no',
        name: `${selected.projectname ?? ""}-${selected.number}`,
        project: selected.projectid,
        is_rejectable: 'Y',
        is_approval: 'Y',
        status: 'P',
        screen_name: `${entity}-Invoice`
      }).then(res => {
        dispatch(notification(res));
      }).catch(err => {
        dispatch(notification(err));
      })
    }

  }

  const deleteInvoice = (confirm, selected) => {

    if (confirm) {
      if (deletePermission) {

        deleteInvoiceService(selected.id, status === "Y" ? "N" : "Y", `${entity}-Invoice`)
          .then(res => {
            dispatch(notification(res))
            search();
          })
          .catch(err => {
            dispatch(notification(err));
          });
      }
      else {
        savePendingService({
          table: 'Invoice',
          pk_id: selected.id,
          type: entity + ' Delete Invoice',
          amount: selected.gross,
          sub: 'inv-no',
          name: `${selected.projectname ?? ""}-${selected.number}`,
          project: selected.projectid,
          is_rejectable: 'Y',
          is_approval: 'Y',
          status: 'P',
          screen_name: `${entity}-Invoice`
        }).then(res => {
          dispatch(notification(res));
        }).catch(err => {
          dispatch(notification(err));
        })
      }
    }
    setShowConfirm({ show: false, callBackData: null });

  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id'
      },
      {
        Header: 'Project',
        accessor: 'projectname',
      },
      {
        Header: 'Name',
        accessor: (cell) => cell['vendorname'] ?? cell['clientname'],
        className: 'w-100p',
        textFilter: true

      },
      {
        Header: 'Type',
        accessor: (cell) => cell['openinvoice'] === 'Y' ? "Open" : cell['type'],
      },
      {
        Header: 'Date',
        accessor: (cell) => cell['date'] ? format(new Date(cell['date']), "dd-MM-yyyy") : "",
      },
      {
        Header: 'Number',
        accessor: 'number',
        textFilter: true
      }, {
        Header: 'Gross',
        accessor: (cell) => formatCurrency(cell['gross']),
        className: 'text-end',
        hideFilter: true
      },{
        Header: 'TDS',
        accessor: (cell) => formatCurrency(cell['tds']),
        className: 'text-end',
        hideFilter: true
      },{
        Header: `${entity.toUpperCase()==="CLIENT"?'Dr':'Cr'} Note`,
        accessor: (cell) => formatCurrency(cell['cr_dr_note']),
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Balance',
        accessor: (cell) => formatCurrency(Number(cell['gross'] ?? 0) - (Number(cell['allocated'] ?? 0) + Number(cell['tds']??0)) ),
        id: 'balance',
        className: 'text-end',
        hideFilter: true
      }, {
        Header: 'Closed',
        id: 'closedDate',
        accessor: (cell) => cell['closedDate'] ? format(new Date(cell['closedDate']), "dd-MM-yyyy") : "",
        show: status === 'active'
      }, {
        Header: 'Action',
        id: 'action',
        hideFilter: true,
        accessor: (cell) => {
          return <React.Fragment>
            {cell.deleted === 'Y' ? "Deleted" :
              <div className="text-center">

                {status !== 'active' ? <Button size="sm" variant="outline-primary" className="pt-0 pb-0 me-2" title="Active Invoice" onClick={() => { activateInvoice(cell['id'], cell['status'], cell) }}> <i className="fa fa-reply" role="button"></i></Button> : null}
                {status === 'active' ? <Button size="sm" variant="outline-primary" className="pt-0 pb-0 me-2" title="Close Invoice" onClick={() => { closeInvoice(cell['id'], cell['status'], cell) }}> <i className="fa fa-times" role="button"></i></Button> : null}
                {status === 'active' ? <Button size="sm" variant="outline-danger" className="pt-0 pb-0" title="Delete Invoice" onClick={() => { setShowConfirm({ show: true, callBackData: cell, message: `${deletePermission ? 'Are you sure, want to delete invoice?' : 'You are not allowed to delete, however this will be created as a requested to elevated users!'}` }) }}> <i className="fa fa-trash" role="button"></i></Button> : null}
              </div>
            }
          </React.Fragment>
        }
      }],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, closePermission, activePermission, deletePermission, entity]
  )

  const rowChange = ({ balance }) => {

    if(balance===0){
      return 'text-success';
    }
    const bal = balance.replace('₹','');
    return Number(bal) <= 0 ? 'text-success' : 'text-danger'
  }

  const setField = (field, value, idx) => {
    dispatch(changeInvoice({ name: field, value, idx }));
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({
      ...errors,
      [field]: null
    })
  }

  const addRow = (row) => {
    dispatch(addInvoiceItem(Number(invoice.items[invoice.items.length - 1].idx) + 1, row));
  }


  const removeRow = (idx) => {
    dispatch(removeInvoiceItem(idx));
  }

  const search = (e) => {

    let filter = null;
    if (entity === 'Client' && selectedClientId) {
      filter = selectedClientId;
    }
    else if (entity === 'Vendor' && selectedVendorId !== null) {
      filter = selectedVendorId;
    }

    // if (filter) {

    if (selectedProjectId && selectedProjectId !== "0") {
      dispatch(loadAllInvoice(status, entity, filter, selectedProjectId, null, execludeAuto, false, startDate ? format(startDate, "yyyy-MM-dd") : null, endDate ? format(endDate, "yyyy-MM-dd") : null, costcenter));

    }
    else if (filter != null) {
      dispatch(loadAllInvoice(status, entity, filter, null, null, execludeAuto, false, startDate ? format(startDate, "yyyy-MM-dd") : null, endDate ? format(endDate, "yyyy-MM-dd") : null, costcenter));
    }
    else if (startDate && endDate) {
      dispatch(loadAllInvoice(status, entity, null, null, null, execludeAuto, false, startDate ? format(startDate, "yyyy-MM-dd") : null, endDate ? format(endDate, "yyyy-MM-dd") : null, costcenter));
    }
    else if (e) {
      dispatch(warningNotification(`Select a valid ${entity} or project to load invoice`))
    }

    // }
  }

  const loadQuanties = (res, id, invoiceid) => {

    loadPurchaseOrderQtyService(id, invoiceid)
      .then((qres) => {
        const qtys = {};
        if (qres) {
          qres.forEach(qe => {
            qtys[qe.description] = { ...qe };
          });
        }

        if (invoiceid) {
          dispatch(loadInvoice({
            ...res,
            items: res.items.map(e => {
              let qty = qtys[e.description] ? qtys[e.description] : { aqty: 0, eqty: 0 };
              return { ...e, aqty: qty['aqty'], eqty: qty['eqty'] }
            })
          }));
        }
        else {

          dispatch(changeInvoiceItems({
            type: res.type,
            items: res.items.map(e => {
              let qty = qtys[e.description] ? qtys[e.description] : { aqty: 0, eqty: 0 };
              return { ...e, qty: 0, aqty: qty['aqty'], eqty: qty['eqty'], updatedAt: null, id: null, createdAt: null, amount: 0 }
            })
          }));
        }


      })
      .catch((qerr) => {
        dispatch(notification(qerr));
      })
  }

  const loadpo = (id, invoiceid) => {

    // dispatch(resetInvoiceWithItems());

    if (id && id !== "0") {

      loadPurchaseOrderService(id)
        .then((res) => {

          // if (invoiceid) {
          loadQuanties(res, id, invoiceid)
          // }
          // else {
          //   dispatch(changeInvoiceItems({
          //     type: res.type,
          //     items: res.items.map(e => { return { ...e, aqty: e.qty, qty: 0, id: null } })
          //   }));
          // }
        })
        .catch(err => {
          dispatch(notification(err));
        })
    }
    else {
      setField("items", [{ idx: 1 }]);
    }
  }

  React.useEffect(() => {

    if (!directAdd) {
      search();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status, entity, costcenter]);

  React.useEffect(() => {
    setSelectedClientId(null);
    setSelectedProjectId(null);

    if (invoice && !directAdd) {
      setField("projectid", null);
      setField("vendorid", null);
      setField("clientid", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costcenter])


  const theme = useSelector((state) => state.ThemeOptions);


  return (
    <React.Fragment>
      <Container fluid className={`${show ? 'd-none' : ''}`}>
        <Card>
          <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>
            <h6 className="flex-fill text-start">{entity} Invoice</h6>

            {createPermission ?

              <Button variant="success" size="sm" onClick={() => handleShow()} titel="New Invoice">
                <i className="fa fa-plus d-md-none d-sm-inline"></i>
                <p className="d-none d-md-inline">Add New</p>
              </Button>
              : null}
          </Card.Header>
          <Card.Body>

            <Row className="mb-3 g-2">

              <Col sm={12} md={3}>
                <div style={{ position: "relative" }} className="dp">
                  <DateRangePicker maxDate={new Date()} id="invoiceDateRangeFilter"
                    
                    format="dd-MM-yyyy"
                    
                    onChange={onChange}
                    value = {[startDate, endDate]}
                    calendarIcon={<i className="fa fa-calendar"></i>}
                    // clearIcon = {null}
                    className="form-control"
                    isClearable={true}
                  ></DateRangePicker>
                  <label className="black-text" htmlFor="invoiceDateRangeFilter">Invoice Date</label>
                </div>
              </Col>

              {
                entity === 'Client' ? (
                  <Col sm={12} md={3} >


                    <Typeahead
                      id="floating-label-clt-filter"
                      onChange={(e) => {
                        setSelectedClientId(e[0]?.id)
                        setSelectedName(e[0]?.name);
                      }}
                      onBlur={() => {
                        if (!selectedClientId) {
                          cltTypeRef.current?.clear();
                          proTypeRef.current?.clear();
                          setSelectedName(null);
                          setSelectedProjectId(null)
                        }
                      }}
                      clearButton
                      labelKey="name"
                      options={filterClientsSearch(clients, costcenter)}
                      ref={cltTypeRef}
                      placeholder="Choose A Client"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingClientFilter" label="Select A Client">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedClientId ? clients.filter(e => e.id === selectedClientId) : []}
                    />

                  </Col>
                ) :
                  <Col sm={12} md={3} >

                    <Typeahead
                      clearButton
                      id="floating-label-ven-filter"
                      onChange={(e) => {
                        setSelectedVendorId(e[0]?.id);
                        setSelectedName(e[0]?.name);
                      }}
                      onBlur={() => {
                        if (selectedVendorId === null) {
                          venTypeRef.current?.clear();
                          setSelectedName(null);
                        }
                      }}
                      labelKey="name"
                      options={filterVendorsSearch(vendors)}
                      ref={venTypeRef}
                      placeholder="Choose A Vendor"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingVendorIdFilter" label="Select A Vendor">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedVendorId !== null ? vendors.filter(e => e.id === selectedVendorId) : []}
                    />

                  </Col>
              }

              <Col sm={12} md={3} >

                <Typeahead
                  id="floating-label-pro-filter"
                  onChange={(e) => {
                    setSelectedProjectId(e[0]?.id)
                    setSelectedPurchaseOrderId(null);
                  }}
                  clearButton
                  labelKey="name"
                  onBlur={() => {
                    if (!selectedProjectId) {
                      proTypeRef.current?.clear();
                    }
                  }}
                  options={projects && ((selectedClientId) || entity === "Vendor") ?
                    filterProjectsSearch(projects, costcenter).filter(e => Number(e.clientid) === Number(selectedClientId) || entity === "Vendor") : []}

                  ref={proTypeRef}
                  placeholder="Choose A Project"
                  renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                    return (

                      <FloatingLabel controlId="floatingProjectFilter" label="Select A Project">
                        <Form.Control
                          {...inputProps}
                          ref={(node) => {
                            inputRef(node);
                            referenceElementRef(node);
                          }}
                        />
                      </FloatingLabel>

                    );
                  }}
                  selected={selectedProjectId ? projects.filter(e => e.id === selectedProjectId) : []}
                />

              </Col>

              <Col sm={12} md="auto">
                <div className="form-check form-switch pt-3">
                  <input className="form-check-input" type="checkbox" id="flexSwitchGeneralExpense"
                    checked={execludeAuto}
                    onChange={(e) => {
                      setExecludeAuto(e.target.checked)
                      
                    }} />
                  <label className="form-check-label" htmlFor="flexSwitchGeneralExpense"><b>Exclude Auto  </b></label>
                </div>
              </Col>

              {/* <Col sm={12} md={3} hidden>

                    <Typeahead
                      id="floating-label-po-filter"
                      onChange={(e) => {
                        setSelectedPurchaseOrderId(e[0]?.id);
                      }}
                      clearButton
                      labelKey="number"
                      onBlur={() => {
                        if (!selectedPurchaseOrderId) {
                          poTypeRef.current?.clear();
                        }
                      }}
                      options={pos && selectedProjectId && selectedProjectId !== "0" ?
                        pos.filter(e => Number(e.projectid) === Number(selectedProjectId)) : []}

                      ref={poTypeRef}
                      placeholder="Choose A PO"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingPoFilter" label="Select A PO">
                            <Form.Control
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                          </FloatingLabel>

                        );
                      }}
                      selected={selectedPurchaseOrderId && pos ? pos.filter(e => e.id === selectedPurchaseOrderId) : []}
                    />

                  </Col> */}

              <Col>
                {
                  !show ? (<div className="d-grid flex-fill d-md-flex gap-2  justify-content-md-end  h-100 align-items-end">


                    <Button variant="primary" size="sm" onClick={() => search('click')}>Search</Button>
                  </div>) : null}
              </Col>
            </Row>


          </Card.Body>
        </Card>
        <RTable columns={columns} data={invoices} title="Invoice" edit={handleShow} status={status} changeStatus={setStatus} hideColumn={status === 'active' ? ['closedDate'] : []} rowChange={rowChange}> </RTable>
      </Container>
      {
        !show ? (
          null) : (
          <Container fluid >

            <Card>
              <Card.Header className={`${theme.headerBackgroundColor} d-flex`}>

                <h6 className="flex-fill text-start ">Invoice Detail </h6>
                <div >

                  {!dback ?
                    <React.Fragment>
                      {
                        ((invoice.id && updatePermission && invoice.deleted === 'N') || (!invoice.id && createPermission)) ?
                          <Button className="me-1" variant="primary" size="sm" onClick={handleSubmit} title="Save">
                            <i className="fa fa-save d-md-none d-sm-inline"></i>
                            <p className="d-none d-md-inline">{!invoice.id ? "Save" : "Update"}</p>
                          </Button> : null
                      }

                      {
                        !invoice.id && createPermission && !directAdd ?
                          <Button className="me-1" variant="success" size="sm" onClick={() => { setShowUpload(true) }} title="Upload">
                            <i className="fa fa-upload d-md-none d-sm-inline" ></i>
                            <p className="d-none d-md-inline">Upload</p>
                          </Button> : null
                      }
                    </React.Fragment>
                    : null}


                  {!directAdd ?
                    <Button variant="warning" size="sm" onClick={() => handleClose()} title="Back">
                      <i className="fa fa-arrow-left d-md-none d-sm-inline"></i>
                      <p className="d-none d-md-inline"> Back</p>
                    </Button> : null}
                </div>

              </Card.Header>
              <Card.Body>
                <Row className="mb-3 g-2">

                  <Col sm={12} md={3} >

                    {
                      entity === 'Client' ?

                        <Typeahead disabled={dclient !== null && dclient !== undefined}
                          id="floating-label-clt"
                          onChange={(e) => {
                            setField('clientid', e[0]?.id)
                          }}
                          clearButton
                          onBlur={() => {
                            if (!invoice.clientid) {
                              cltEntryTypeRef.current?.clear();
                              proEntryTypeRef.current?.clear();
                              setField('projectid', null);
                            }
                          }}
                          labelKey="name"
                          options={filterClient(clients, costcenter)}
                          ref={cltEntryTypeRef}
                          placeholder="Choose A Client"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingClient" label="Select A Client">
                                <Form.Control
                                  isInvalid={!!errors.mapping}
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.mapping} </Form.Control.Feedback>
                              </FloatingLabel>

                            );
                          }}
                          selected={invoice.clientid ? clients.filter(e => e.id === Number(invoice.clientid)) : []}
                        />
                        :
                        <Typeahead disabled={dvendor !== null && dvendor !== undefined}
                          id="floating-label-ven"
                          onChange={(e) => {
                            setField('vendorid', e[0]?.id)
                          }}
                          clearButton
                          onBlur={() => {
                            if (invoice.vendorid === null) {
                              venEntryTypeRef.current?.clear();
                            }
                          }}
                          labelKey="name"
                          options={vendors}
                          ref={venEntryTypeRef}
                          placeholder="Choose A Vendor"
                          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                            return (

                              <FloatingLabel controlId="floatingVendorId" label="Select A Vendor">
                                <Form.Control isInvalid={!!errors.mapping}
                                  {...inputProps}
                                  ref={(node) => {
                                    inputRef(node);
                                    referenceElementRef(node);
                                  }}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.mapping} </Form.Control.Feedback>
                              </FloatingLabel>

                            );
                          }}
                          selected={invoice.vendorid !== null ? vendors.filter(e => e.id === Number(invoice.vendorid)) : []}
                        />
                    }

                  </Col>



                  <Col sm={12} md={3} >

                    <Typeahead disabled={dproject}
                      id="floating-label-pro"
                      onChange={(e) => {
                        setField('projectid', e[0]?.id)
                        setField("poid", null);
                        setField("items", [{ idx: 1 }]);
                      }}
                      clearButton
                      labelKey="name"
                      onBlur={() => {
                        if (!invoice.projectid) {
                          proEntryTypeRef.current?.clear();
                        }
                      }}
                      options={projects && ((invoice.clientid && invoice.clientid !== "0") || entity === "Vendor") ?
                        filterProjects(projects, costcenter).filter(e => Number(e.clientid) === Number(invoice.clientid) || entity === "Vendor") : []}

                      ref={proEntryTypeRef}

                      placeholder="Choose A Project"
                      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                        return (

                          <FloatingLabel controlId="floatingProject" label="Select A Project">
                            <Form.Control isInvalid={!!errors.project}
                              {...inputProps}
                              ref={(node) => {
                                inputRef(node);
                                referenceElementRef(node);
                              }}
                            />
                            <Form.Control.Feedback type='invalid'>{errors.project} </Form.Control.Feedback>
                          </FloatingLabel>

                        );
                      }}
                      selected={invoice.projectid ? projects.filter(e => e.id === Number(invoice.projectid)) : []}
                    />


                  </Col>

                  {
                    entity === 'Client' ?
                      <Col sm={12} md={3} >
                        <FloatingLabel controlId="floatingPurchaseOrder" label="Purchase Order" >
                          <Form.Select id="floatingPurchaseOrder" aria-label="Purchase Order" isInvalid={!!errors.po} disabled={invoice.openinvoice === 'Y'}
                            value={invoice.poid ?? "0"}
                            onChange={e => {
                              setField("poid", e.target.value);
                              loadpo(e.target.value);
                            }
                            }>
                            <option value="0">Select Purchase Order</option>
                            {
                              pos && invoice.projectid && invoice.projectid !== "0" ?
                                pos.filter(e => Number(e.projectid) === Number(invoice.projectid))
                                  .map((e, i) => {
                                    return <option key={`pro_${i}`} value={e.id} >{e.number}</option>
                                  }) : null
                            }

                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.po} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col> :
                      <Col sm={12} md={3} >
                        <FloatingLabel controlId="floatingPurchaseOrder" label="Purchase Order" >
                          <Form.Select id="floatingPurchaseOrder" aria-label="Purchase Order" isInvalid={!!errors.po} disabled={invoice.openinvoice === 'Y'}
                            value={invoice.poid ?? "0"}
                            onChange={e => {
                              setField("poid", e.target.value);
                              loadpo(e.target.value);
                            }
                            }>
                            <option value="0">Select Purchase Order</option>
                            {
                              pos && invoice.projectid && invoice.projectid !== "0" && invoice.vendorid !== null ?
                                pos.filter(e => Number(e.projectid) === Number(invoice.projectid) && Number(e.vendorid) === Number(invoice.vendorid))
                                  .map((e, i) => {
                                    return <option key={`pro_${i}`} value={e.id} >{e.number}</option>
                                  }) : null
                            }

                          </Form.Select>
                          <Form.Control.Feedback type='invalid'>{errors.po} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>

                  }




                  <Col sm={12} md={3} >

                    <div style={{ position: "relative" }} className="dp">
                      <DatePicker maxDate={new Date()}
                        minDate={minDate ? new Date(minDate) : null}
                        id="invoiceDate"
                        isInvalid={true}
                        value={invoice && invoice.date ? new Date(invoice.date) : null}
                        

                        format="dd-MM-yyyy"
                        calendarIcon={<i className="fa fa-calendar"></i>}
                        clearIcon = {null}
                        className={`form-control ${!!errors.date ? "is-invalid" : ""}`}
                        //isInvalid={true}
                        
                        onChange={(date) => setField("date",date)}  


                      ></DatePicker>
                      <label className="black-text" htmlFor="invoiceDate">Invoice Date</label>
                    </div>


                  </Col>

                  <Col sm={12} md={3}>
                    <FloatingLabel controlId="floatingNumber" label="Invoice Number">
                      <Form.Control type="text" placeholder="Invoice Number" isInvalid={!!errors.number} value={invoice.number ?? ""} 
                      onChange={e => setField("number", e.target.value)} disabled={invoice.autonumber==='Y' }/>
                      <Form.Control.Feedback type='invalid'>{errors.number} </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>


                  <Col sm={12} md={3} >
                    <FloatingLabel controlId="floatingType" label="Type">
                      <Form.Select id="floatingType" aria-label="Type" disabled isInvalid={!!errors.type} value={invoice.type ?? "0"} onChange={e => setField("type", e.target.value)} >
                        <option value="0">Select Type</option>
                        <option value="SINGLE">Single Line</option>
                        <option value="MULTI">Multi Line</option>
                      </Form.Select>
                      <Form.Control.Feedback type='invalid'>{errors.type} </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  {
                    invoice.vendorid === 0 ?

                      <Col sm={12} md={3}>
                        <FloatingLabel controlId="floatingVendorName" label="Vendor Name" disabled={dvendorname}>
                          <Form.Control type="text" placeholder="Vendor Name" isInvalid={!!errors.vendor_name} value={invoice.vendor_name ?? ""} onChange={e => setField("vendor_name", e.target.value)} />
                          <Form.Control.Feedback type='invalid'>{errors.vendor_name} </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col> : null
                  }


                  <Col sm={12} md={3}>
                    <div className="form-check form-switch pt-3">
                      <input className="form-check-input" disabled={invoice.id} type="checkbox" id="flexSwitchInvoiceType" checked={invoice.openinvoice === 'Y'}
                        onChange={(e) => {
                          setField("openinvoice", e.target.checked ? 'Y' : 'N');
                          setField("items", [{ idx: 1 }]);
                          setField("po", null);
                          setField("poid", null);
                        }

                        } />
                      <label className="form-check-label" htmlFor="flexSwitchInvoiceType"><b>Open Invoice </b></label>
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="form-check form-switch pt-3">
                      <input className="form-check-input" disabled={invoice.id} type="checkbox" id="flexSwitchInvoiceNumber" checked={invoice.autonumber === 'Y'}
                        onChange={(e) => {
                          setField("autonumber", e.target.checked ? 'Y' : 'N');
                          if(e.target.checked){
                            setField("number", "D-INV-");
                          }
                          else{
                            setField("number", "");
                          }
                        }

                        } />
                      <label className="form-check-label" htmlFor="flexSwitchInvoiceNumber"><b>Auto Append Inv Number </b></label>
                    </div>
                  </Col>


                </Row>
                {
                  errors.description ? <div className="alert alert-warning  p-0 text-center " > {errors.description} </div> : null
                }
                {
                  invoice.openinvoice === 'Y' ? (
                    <Table striped bordered hover responsive="md" size='md'  >
                      <thead className={` ${theme.headerBackgroundColor}`}>
                        <tr> <th className="text-center" style={{ width: "50px" }}>#</th>
                          <th className="text-center">Item</th>
                          <th className="text-center">Description</th>
                          <th className="text-center" >Qty</th>
                          <th className="text-center" >Rate</th>
                          <th className="text-center" style={{ width: "100px" }}>GST%</th>
                          <th className="text-center" style={{ width: "150px" }}>GST Value  </th>
                          <th className="text-center" style={{ width: "150px" }}>Amount</th>
                          {
                            ((invoice.id && updatePermission) || (!invoice.id && createPermission)) ?
                              <th style={{ width: "50px" }}>Action</th> : null
                          }
                        </tr>
                      </thead>
                      <tbody className="editable">

                        {
                          invoice.items && invoice.items.length > 0 ? invoice.items.map((item, i) => {

                            return (<tr key={`tr_${i}`}>
                              <td className="align-middle text-end pe">{i + 1}</td>
                              <td><input name="itemtxt" type="text" className="form-control" value={item.item ?? ""} onChange={e => setField("item", e.target.value, item.idx)} /></td>
                              <td><input name="descriptiontxt" type="text" className="form-control" value={item.description ?? ""} onChange={e => setField("description", e.target.value, item.idx)} /></td>
                              <td><input name="qtytxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.qty ?? ""} onChange={e => setField("qty", e.target.value, item.idx)} /></td>
                              <td><input name="ratetxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.rate ?? ""} onChange={e => setField("rate", e.target.value, item.idx)} /></td>
                              <td><input name="gstpertxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.gstpercentage ?? "0"} onChange={e => setField("gstpercentage", e.target.value, item.idx)} /></td>
                              <td className="align-middle text-end pe">{formatAccounting(item.gstvalue ?? 0)} </td>

                              <td className="align-middle text-end pe">{formatAccounting(item.basic ?? 0)} </td>
                              {
                                ((invoice.id && updatePermission) || (!invoice.id && createPermission)) ?
                                  <td className="text-center action">
                                    {
                                      i !== 0 ? <i className="fa fa-trash text-danger " role="button" onClick={() => { removeRow(item.idx) }}></i> : (
                                        <i className="fa fa-plus  text-success" role="button" onClick={() => { addRow() }}> </i>)
                                    }
                                  </td> : null}
                            </tr>)

                          }) : null
                        }

                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={7} className="text-end">TOTAL BASIC</td>
                          <td colSpan={2} className="p-0 text-end">{formatAccounting(invoice.basic ?? 0)}</td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="text-end">MISC/TRANSPORT</td>
                          <td colSpan={2} className="p-0"><input name="misctxt" type="text" onKeyPress={numberCheck} className="form-control text-end" value={invoice.misc ?? ""} onChange={e => setField("misc", e.target.value)} /></td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="text-end">GST</td>
                          <td colSpan={2} className="text-end">{formatAccounting(invoice.gst ?? 0)}</td>
                        </tr>
                        <tr>
                          <td colSpan={7} className="text-end">GROSS</td>
                          <td colSpan={2} className="text-end">{formatCurrency(invoice.gross ?? 0)}</td>
                        </tr>
                      </tfoot>


                    </Table>) : (
                    invoice.type ? (
                      <React.Fragment>

                        {
                          invoice.type === "SINGLE" ? (<Container fluid className="p-0" >
                            <Table bordered hover responsive="md" size='md'  >
                              <thead className={` ${theme.headerBackgroundColor}`}>
                                <tr><th className="text-center" style={{ width: "50px" }}>#</th>
                                  <th className="text-center">Description</th>
                                  <th className="text-center">Avail Qty</th>
                                  <th className="text-center">Billed Qty</th>
                                  <th className="text-center">Currrent Qty</th>
                                  <th className="text-center" >Rate</th>
                                  <th className="text-center" style={{ width: "100px" }}>GST%</th>
                                  <th className="text-center" style={{ width: "150px" }}>GST Value  </th>
                                  <th className="text-center" style={{ width: "150px" }}>Amount</th>
                                </tr>
                              </thead>
                              <tbody className="editable">

                                {
                                  invoice.items && invoice.items.length > 0 ? invoice.items.map((item, i) => {

                                    return (<tr key={`tr_${i}`}>
                                      <td className="align-middle text-end pe">{item.idx ?? ""}</td>
                                      <td><input name="descriptiontxt" type="text" className="form-control" value={item.description ?? ""} onChange={e => setField("description", e.target.value, item.idx)} /></td>
                                      <td className="align-middle text-end pe">{item.aqty ?? "0"} </td>
                                      <td className="align-middle text-end">{item.eqty ?? "0"} </td>
                                      <td><input name="qtytxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.qty ?? "0"} onChange={e => setField("qty", e.target.value, item.idx)} /></td>
                                      <td><input name="ratetxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.rate ?? "0"} onChange={e => setField("rate", e.target.value, item.idx)} /></td>
                                      <td><input name="gstpertxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.gstpercentage ?? "0"} onChange={e => setField("gstpercentage", e.target.value, item.idx)} /></td>
                                      <td className="align-middle text-end pe">{formatAccounting(item.gstvalue ?? 0)} </td>
                                      <td><input name="amounttxt" type="text" onKeyPress={numberCheck} className="form-control" value={item.amount ?? ""} onChange={e => setField("amount", e.target.value, item.idx)} /></td>
                                    </tr>)

                                  }) : null
                                }

                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={8} className="text-end">MISC/TRANSPORT</td>
                                  <td className="p-0"><input name="misctxt" type="text" onKeyPress={numberCheck} className="form-control text-end" value={invoice.misc ?? ""} onChange={e => setField("misc", e.target.value)} /></td>
                                </tr>
                                <tr>
                                  <td colSpan={8} className="text-end">GST</td>
                                  <td className="text-end">{formatAccounting(invoice.gst ?? 0)}</td>
                                </tr>
                                <tr>
                                  <td colSpan={8} className="text-end">GROSS</td>
                                  <td className="text-end">{formatAccounting(invoice.gross ?? 0)}</td>
                                </tr>
                              </tfoot>


                            </Table>
                          </Container>) : (<Container fluid className="p-0" >
                            <Table bordered hover responsive="md" size='md'  >
                              <thead className={` ${theme.headerBackgroundColor}`}>
                                <tr><th className="text-center" style={{ width: "50px" }}>#</th>
                                  <th className="text-center">Item</th>
                                  <th className="text-center">Description</th>
                                  <th className="text-center">HSN/SAC</th>
                                  <th className="text-center">Avail Qty</th>
                                  <th className="text-center">Billed Qty</th>
                                  <th className="text-center">Currrent Qty</th>
                                  <th className="text-center">Rate</th>
                                  <th className="text-center" style={{ width: "100px" }}>Gst %</th>
                                  <th className="text-center">Gst Value</th>
                                  <th className="text-center">Basic</th>
                                  {
                                    ((invoice.id && updatePermission) || (!invoice.id && createPermission)) ?
                                      <th style={{ width: "50px" }}>Action</th> : null}
                                </tr>
                              </thead>
                              <tbody className="editable">

                                {
                                  invoice.items && invoice.items.length > 0 ? invoice.items.map((itm, i) => {

                                    return (<tr key={`tr_${i}`} >
                                      <td className="align-middle text-end pe">{itm.idx ?? ""} </td>
                                      <td><input name="itemtxt" type="text" className="form-control" value={itm.item ?? ""} onChange={e => setField("item", e.target.value, itm.idx)} /></td>
                                      <td><input name="desctxt" type="text" className="form-control" value={itm.description ?? ""} onChange={e => setField("description", e.target.value, itm.idx)} /></td>

                                      <td><input name="hsntxt" type="text" className="form-control" value={itm.hsn ?? ""} onChange={e => setField("hsn", e.target.value, itm.idx)} /></td>
                                      <td className="align-middle text-end pe">{itm.aqty ?? "0"} </td>
                                      <td className="align-middle text-end">{itm.eqty ?? "0"} </td>
                                      <td><input name="qtytxt" type="text" onKeyPress={numberCheck} className="form-control" value={itm.qty ?? "0"} onChange={e => setField("qty", e.target.value, itm.idx)} /></td>
                                      <td><input name="ratetxt" type="text" onKeyPress={numberCheck} className="form-control" value={itm.rate ?? "0"} onChange={e => setField("rate", e.target.value, itm.idx)} /></td>
                                      <td><input name="gstpertxt" type="text" onKeyPress={numberCheck} className="form-control" value={itm.gstpercentage ?? "0"} onChange={e => setField("gstpercentage", e.target.value, itm.idx)} /></td>
                                      <td className="align-middle text-end pe">{formatAccounting(itm.gstvalue ?? 0)} </td>
                                      <td className="align-middle text-end pe">{formatAccounting(itm.basic ?? 0)} </td>
                                      {
                                        ((invoice.id && updatePermission) || (!invoice.id && createPermission)) ?
                                          <td className="text-center action">
                                            {
                                              <i className="fa fa-trash text-danger " role="button" onClick={() => { removeRow(itm.idx) }}></i>
                                            }
                                          </td> : null}
                                    </tr>)

                                  }) : null
                                }

                              </tbody>
                              <tfoot>
                                <tr >
                                  <td colSpan={10} className="text-end">Total Basic</td>
                                  <td colSpan={2} className="text-end">{formatAccounting(invoice.basic)}</td>
                                </tr>
                                <tr>
                                  <td colSpan={10} className="text-end">GST</td>
                                  <td colSpan={2} className="text-end">{formatAccounting(invoice.gst)}</td>
                                </tr>
                                <tr >
                                  <td colSpan={10} className="text-end">MISC/TRANSPORT</td>
                                  <td className="p-0" colSpan={2}><input name="misctxt" onKeyPress={numberCheck} type="text" className="form-control text-end" value={invoice.misc ?? ""} onChange={e => setField("misc", e.target.value)} /></td>
                                </tr>
                                <tr>
                                  <td colSpan={10} className="text-end">GROSS</td>
                                  <td colSpan={2} className="text-end">{formatCurrency(invoice.gross)}</td>
                                </tr>
                              </tfoot>


                            </Table>
                          </Container>)
                        }
                      </React.Fragment>
                    ) : null)
                }





              </Card.Body>
            </Card>
            <Modal show={showUpload} onHide={handleCloseUpload}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Purchase Order</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formFile" >
                  <Form.Control size="lg" type="file" accept=".xlsx" />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>

                <Button variant="primary" onClick={handleCloseUpload}>
                  Upload
                </Button>
              </Modal.Footer>
            </Modal>


          </Container>)
      }
      <Confirm show={showConfirm} confirmation={showConfirm.message} confirmCallBack={deleteInvoice}></Confirm>
    </React.Fragment >);
}

// }
